import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { messagesEn } from "./messagesEn";
import { messagesPt } from "./messagesPt";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: "en",
        resources: {
            en: { translation: messagesEn() },
            pt: { translation: messagesPt() },
        },
        detection: {
            order: ["navigator", "localStorage"],
        },
        interpolation: {
            escapeValue: false,
        },
    })
    .then(() => initReactI18next.init(i18n));
