import React from "react";
import { Box, Button, Grid } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import { GridItem, useStyles } from "./styles";
import { useTranslation } from "react-i18next";

export const FormButtons = () => {
    const styles = useStyles();
    const { t: translate } = useTranslation();
    const handleClearClick = () => {
        window.location.reload();
    };
    return (
        <Box className={styles.boxButtons}>
            <Grid item xs={0}>
                <GridItem elevation={0}>
                    <Button
                        disableElevation
                        data-testid="btnSubmit"
                        className="buttons"
                        variant="contained"
                        type="submit"
                        startIcon={<SaveIcon />}>
                        {translate("form.buttons.submit")}
                    </Button>
                </GridItem>
            </Grid>
            <Grid item xs={0}>
                <GridItem elevation={0}>
                    <Button
                        disableElevation
                        className="buttons"
                        color="error"
                        variant="outlined"
                        startIcon={<ClearIcon />}
                        onClick={handleClearClick}>
                        {translate("form.buttons.clear")}
                    </Button>
                </GridItem>
            </Grid>
        </Box>
    );
};
