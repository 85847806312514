import { object, ObjectSchema, ValidationError } from "yup";
import { PatientUserDetails } from "../model/PatientUserDetails";
import * as yup from "yup";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { validatePhoneNumber } from "../utils/utils";

export const useValidationResolver = () => {
    const { t: translate } = useTranslation();

    const validationSchema: ObjectSchema<PatientUserDetails> = object({
        healthcareServiceIdentifier: yup
            .number()
            .max(999999999, `${translate("form.fields.identifier.helperText.minAndMaxLength")}`)
            .min(100000000, `${translate("form.fields.identifier.helperText.minAndMaxLength")}`)
            .typeError(`${translate("form.fields.generalHelperText.required")}`)
            .required(`${translate("form.fields.generalHelperText.required")}`),
        firstName: yup
            .string()
            .max(20, `${translate("form.fields.name.helperText.maxLength")}`)
            .min(3, `${translate("form.fields.name.helperText.minLength")}`)
            .matches(/^[\p{L}\s]+$/u, `${translate("form.fields.name.helperText.pattern")}`)
            .required(`${translate("form.fields.generalHelperText.required")}`),
        lastName: yup
            .string()
            .max(20, `${translate("form.fields.name.helperText.maxLength")}`)
            .min(3, `${translate("form.fields.name.helperText.minLength")}`)
            .matches(/^[\p{L}\s]+$/u, `${translate("form.fields.name.helperText.pattern")}`)
            .required(`${translate("form.fields.generalHelperText.required")}`),
        dateOfBirth: yup
            .date()
            .typeError(`${translate("form.fields.generalHelperText.required")}`)
            .required(`${translate("form.fields.generalHelperText.required")}`)
            .max(new Date(), `${translate("form.fields.dateOfBirth.helperText.invalid")}`),
        gender: yup.string().required(`${translate("form.fields.generalHelperText.required")}`),
        phoneNumber: yup
            .string()
            .required(`${translate("form.fields.generalHelperText.required")}`)
            .test(
                "validNumber",
                `${translate("form.fields.contactPoint.helperText.phonePattern")}`,
                (value: string) => {
                    if (value) {
                        return validatePhoneNumber(value);
                    }
                    return false;
                }
            ),
        email: yup
            .string()
            .email(`${translate("form.fields.contactPoint.helperText.emailPattern")}`)
            .when("useP5Card", (useP5Card, schema) => {
                if (!useP5Card[0]) {
                    return yup
                        .string()
                        .email(`${translate("form.fields.contactPoint.helperText.emailPattern")}`)
                        .required(`${translate("form.fields.generalHelperText.required")}`);
                }
                return schema;
            }),
        dataManagement: yup
            .boolean()
            .isTrue(`${translate("form.checkboxes.helperText.required")}`)
            .required(),
        trueInfo: yup
            .boolean()
            .isTrue(`${translate("form.checkboxes.helperText.required")}`)
            .required(),
        termsAndConditions: yup
            .boolean()
            .isTrue(`${translate("form.checkboxes.helperText.required")}`)
            .required(),
        emailMarketingSubscribe: yup.boolean(),
        protocol: yup.string(),
        cardCode: yup.string().when("useP5Card", (useP5Card, schema) => {
            if (useP5Card[0]) {
                return yup.string().required(`${translate("form.fields.generalHelperText.required")}`);
            }
            return schema;
        }),
        cardPin: yup.string().when("useP5Card", (useP5Card, schema) => {
            if (useP5Card[0]) {
                return yup.string().required(`${translate("form.fields.generalHelperText.required")}`);
            }
            return schema;
        }),
        createAccount: yup.boolean(),
        medicalSummary: yup.string(),
        useP5Card: yup.boolean(),
    });

    return { validationSchema };
};
export const useYupValidationResolver = (validationSchema: ObjectSchema<PatientUserDetails>) => {
    return useCallback(
        async (data: PatientUserDetails) => {
            try {
                const values: PatientUserDetails = await validationSchema.validate(data, {
                    abortEarly: false,
                });
                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: ValidationError, currentError: ValidationError) => ({
                            ...allErrors,
                            [currentError.path!]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );
};
