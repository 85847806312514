import { getEnvironmentVariable } from "./utils";

const serverTerminologyUrl: string | undefined = getEnvironmentVariable("REACT_APP_SERVER_TERMINOLOGY_URL");

export const defaultProtocolsMapping: { [key: string]: string } = {
    none: "Sem protocolo",
    sioslife: "SiosLife",
};

export const getDefaultProtocol = (code: string): string => {
    return `{"system":"${serverTerminologyUrl}/CodeSystem/partnership","version":"1.1.0","code":"${code}","display":"${defaultProtocolsMapping[code]}"}`;
};
