import { makeStyles } from "@material-ui/styles";
import { Paper, styled } from "@mui/material";

export const useStyles = makeStyles(() => {
    return {
        formFields: {
            width: "100%",
            textAlign: "left",
        },
        boxButtons: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            "& .buttons": {
                width: "120px",
            },
        },
        checkboxHelpError: {
            margin: "-4px 0px 4px 33px !important",
        },
        recaptchaBadge: {
            marginTop: "5px",
            display: "flex",
            justifyContent: "center",
        },
        errorViewBox: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            "& .errorViewMessage": {
                padding: "10px",
            },
            "& .errorViewDescription": {
                padding: "10px",
            },
        },
    };
});

export const GridItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));
