import React from "react";
import { Checkbox, FormControlLabel, FormHelperText, Grid } from "@mui/material";
import { PatientFormProps } from "../../model/PatientUserDetails";
import { GridItem, useStyles } from "./styles";
import { createMarkup } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const AgreeCheckboxes = ({ errors, register }: PatientFormProps) => {
    const styles = useStyles();
    const location = useLocation();
    const { t: translate } = useTranslation();
    return (
        <>
            <Grid item xs={12}>
                <GridItem elevation={0}>
                    <FormControlLabel
                        control={<Checkbox />}
                        label={`${translate("form.checkboxes.dataManagement")}`}
                        className={styles.formFields}
                        {...register("dataManagement")}
                    />
                    {errors.dataManagement && (
                        <FormHelperText className={styles.checkboxHelpError} error>
                            {errors.dataManagement.message?.toString()}
                        </FormHelperText>
                    )}

                    <FormControlLabel
                        control={<Checkbox />}
                        label={`${translate("form.checkboxes.trueInfo")}`}
                        className={styles.formFields}
                        {...register("trueInfo")}
                    />
                    {errors.trueInfo && (
                        <FormHelperText className={styles.checkboxHelpError} error>
                            {errors.trueInfo.message?.toString()}
                        </FormHelperText>
                    )}

                    <FormControlLabel
                        control={<Checkbox />}
                        label={
                            <div
                                dangerouslySetInnerHTML={createMarkup(
                                    `${translate("form.checkboxes.termsAndConditions")}`
                                )}
                            />
                        }
                        className={styles.formFields}
                        {...register("termsAndConditions")}
                    />
                    {errors.termsAndConditions && (
                        <FormHelperText className={styles.checkboxHelpError} error>
                            {errors.termsAndConditions.message?.toString()}
                        </FormHelperText>
                    )}

                    {location.pathname === "/patient-p5" && (
                        <FormControlLabel
                            control={<Checkbox />}
                            label={
                                <div
                                    dangerouslySetInnerHTML={createMarkup(
                                        `${translate("form.checkboxes.emailMarketing")}`
                                    )}
                                />
                            }
                            className={styles.formFields}
                            {...register("emailMarketingSubscribe", { required: false })}
                        />
                    )}
                </GridItem>
            </Grid>
        </>
    );
};
