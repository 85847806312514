export const messagesPt = () => {
    return {
        form: {
            fields: {
                p5Card: {
                    cardCode: {
                        label: "Código do cartão",
                        helperText: {
                            text: "Poderá encontrar o código no verso do cartão P5.",
                        },
                    },
                    cardPin: {
                        label: "PIN do cartão",
                        helperText: {
                            text: "Poderá encontrar o PIN no verso do cartão P5.",
                        },
                    },
                },
                identifier: {
                    label: "Número de utente de saúde",
                    helperText: {
                        text: "Poderá encontrar o seu número de utente de saúde na parte de trás do seu Cartão de Cidadão.",
                        minAndMaxLength: "Deve ter 9 caracteres",
                    },
                },
                name: {
                    firstName: "Nome",
                    lastName: "Sobrenome",
                    helperText: {
                        maxLength: "Máximo de 20 caracteres",
                        minLength: "Mínimo de 3 caracteres",
                        pattern: "O nome não pode conter números nem caracteres especiais",
                    },
                },
                dateOfBirth: {
                    label: "Data de nascimento",
                    helperText: {
                        invalid: "Data de nascimento inválida",
                    },
                },
                gender: {
                    label: "Sexo",
                    male: "Masculino",
                    female: "Feminino",
                    unknown: "Desconhecido",
                    other: "Prefiro não revelar",
                },
                contactPoint: {
                    phoneNumberLabel: "Número de telefone",
                    emailLabel: "Email",
                    helperText: {
                        phonePattern: "O contacto inserido não é válido",
                        emailPattern: "O email inserido não é válido",
                    },
                },
                protocols: {
                    label: "Protocolo",
                    onError: "Não foi possivel carregar os protocolos",
                },
                domainResource: {
                    textLabel: "Resumo clínico",
                },
                generalHelperText: {
                    required: "Campo obrigatório",
                },
            },
            checkboxes: {
                dataManagement: "Consinto o tratamento dos dados solicitados para contacto e/ou prestação de serviços.",
                trueInfo: "Declaro que todas as informações fornecidas são verdadeiras.",
                termsAndConditions: `Li e aceito os <a href="https://www.p5.pt/politicas-de-privacidade/avaliador-de-sintomas/" target="_blank">termos e condições e a política de privacidade</a>.`,
                p5Card: "Pretendo usar o meu cartão de acesso P5",
                emailMarketing:
                    "Quero receber comunicações por email do P5 para ter conhecimento das novidades e receber eventuais ofertas e promoções.",
                helperText: {
                    required: "Seleção obrigatória",
                },
            },
            buttons: {
                submit: "Registar",
                clear: "Limpar",
            },
        },
        errorView: {
            error500: {
                description: "A ligação aos serviços necessários não foi estabelecida.",
            },
            error401: {
                description: "Falha na autenticação. Não tem permissões para aceder a alguns recursos nesta página.",
            },
        },
        toast: {
            pending: "A registar os seus dados. Aguarde, por favor...",
            success: {
                regularPatient: "O seu registo foi efetuado com sucesso!",
                p5Patient:
                    "O seu registo no P5 foi efetuado com sucesso! Deverá receber um e-mail nos próximos momentos para definir a sua password.",
            },
            errors: {
                400: "Não é possível avançar com o registo. Por favor, verifique os dados inseridos.",
                409: "Já existe um utente registado com estes dados. Por favor, inicie a sessão ou recupere a sua palavra-passe.",
                424: "Falha na comunicação com o servidor. Por favor, tente novamente mais tarde.",
                default: "Não foi possível efetuar o seu registo. Por favor, tente novamente mais tarde.",
            },
        },
        recaptcha: {
            error: "Falha na verificação do recaptcha",
        },
    };
};
