import AbstractHttpClient from "./AbstractHttpClient";
import { PatientUserDetails, UserServiceModel } from "../model/PatientUserDetails";
import { isNil } from "lodash";
import { ReCAPTCHARequest } from "../model/ReCAPTCHA";
import { getEnvironmentVariable } from "../utils/utils";

export default class UsersServiceHttpClient extends AbstractHttpClient<UserServiceModel> {
    private static USER_SERVICE_URL: string | undefined = getEnvironmentVariable("REACT_APP_USER_SERVICE_URL");
    private static RECAPTCHA_KEY: string | undefined = getEnvironmentVariable("REACT_APP_RECAPTCHA_KEY");

    private static PATIENTS_ENDPOINT: string = "patients";
    private static RECAPTCHA_ENDPOINT: string = "recaptchaValidation";

    private static instance: UsersServiceHttpClient;

    constructor() {
        if (isNil(UsersServiceHttpClient.USER_SERVICE_URL)) {
            throw new Error("REACT_APP_USER_SERVICE_URL variable is null or undefined");
        }
        if (isNil(UsersServiceHttpClient.RECAPTCHA_KEY)) {
            throw new Error("REACT_APP_RECAPTCHA_KEY variable is null or undefined");
        }
        super(UsersServiceHttpClient.USER_SERVICE_URL);
    }

    public static getInstance(): UsersServiceHttpClient {
        if (!UsersServiceHttpClient.instance) {
            UsersServiceHttpClient.instance = new UsersServiceHttpClient();
        }
        return UsersServiceHttpClient.instance;
    }

    protected getHeaders(): Headers {
        const headers: Headers = new Headers();
        headers.append("Content-Type", "application/json");
        return headers;
    }

    async createPatientUserDetails(data: PatientUserDetails): Promise<Response> {
        return this.post(UsersServiceHttpClient.PATIENTS_ENDPOINT, data);
    }

    async verifyRecaptcha(token: string): Promise<Response> {
        return await this.post(UsersServiceHttpClient.RECAPTCHA_ENDPOINT, new ReCAPTCHARequest(token));
    }
}
