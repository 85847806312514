import React from "react";
import { Card, CardContent, Grid, useMediaQuery } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PatientUserDetails } from "../../model/PatientUserDetails";
import { showToast } from "../../utils/utils";
import PatientPageController from "./PatientPageController";
import { P5PatientForm } from "../components/P5PatientForm";
import { AgreeCheckboxes } from "../components/AgreeCheckboxes";
import { FormButtons } from "../components/FormButtons";
import { GoogleReCaptchaBadge } from "../components/GoogleReCaptchaBadge";
import { useValidationResolver, useYupValidationResolver } from "../../validations/yupValidations";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCAPTCHAService from "../../services/ReCAPTCHAService";

export const P5PatientPage = () => {
    const smallDevices = useMediaQuery("(max-width: 600px)");
    const { t: translate } = useTranslation();
    const { executeRecaptcha: recaptcha } = useGoogleReCaptcha();
    const { validationSchema } = useValidationResolver();
    const resolver = useYupValidationResolver(validationSchema);
    const patientPageController: PatientPageController = PatientPageController.getInstance();
    const recaptchaService: ReCAPTCHAService = ReCAPTCHAService.getInstance();
    const {
        register,
        setError,
        clearErrors,
        handleSubmit,
        formState: { errors },
    } = useForm<PatientUserDetails>({ resolver });
    const onSubmit: SubmitHandler<PatientUserDetails> = async (data: PatientUserDetails) => {
        recaptchaService
            .validateRecaptcha(recaptcha)
            .then(async () => {
                await showToast(patientPageController.createPatient(data, true), translate, true);
            })
            .catch((error: Error) => {
                toast(translate("recaptcha.error"), { type: "error" });
                throw new Error(error.message);
            });
    };
    return (
        <Card>
            <CardContent component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} direction={smallDevices ? "column" : "row"}>
                    <P5PatientForm
                        errors={errors}
                        register={register}
                        setError={setError}
                        clearErrors={clearErrors}
                        emailRequired={false}
                    />
                    <AgreeCheckboxes errors={errors} register={register} />
                </Grid>
                <GoogleReCaptchaBadge />
                <FormButtons />
            </CardContent>
        </Card>
    );
};
