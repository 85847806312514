import { ReasonPhrases, StatusCodes } from "http-status-codes";

export class CustomHttpError {
    httpCode: number;
    name: string;
    constructor(httpCode: number, name: string) {
        this.httpCode = httpCode;
        this.name = name;
    }
}
export class ConflictError extends CustomHttpError {
    constructor() {
        super(StatusCodes.CONFLICT, ReasonPhrases.CONFLICT);
    }
}
export class FailedDependency extends CustomHttpError {
    constructor() {
        super(StatusCodes.FAILED_DEPENDENCY, ReasonPhrases.FAILED_DEPENDENCY);
    }
}
export class BadRequestError extends CustomHttpError {
    constructor() {
        super(StatusCodes.BAD_REQUEST, ReasonPhrases.BAD_REQUEST);
    }
}
export class UnauthorizedError extends CustomHttpError {
    constructor() {
        super(StatusCodes.UNAUTHORIZED, ReasonPhrases.UNAUTHORIZED);
    }
}
export class InternalServerError extends CustomHttpError {
    constructor() {
        super(StatusCodes.INTERNAL_SERVER_ERROR, ReasonPhrases.INTERNAL_SERVER_ERROR);
    }
}
export class ErrorHandler {
    static getErrorNameByCode(errorCode: number): ConflictError {
        switch (errorCode) {
            case StatusCodes.CONFLICT:
                return new ConflictError();
            case StatusCodes.FAILED_DEPENDENCY:
                return new FailedDependency();
            case StatusCodes.BAD_REQUEST:
                return new BadRequestError();
            case StatusCodes.UNAUTHORIZED:
                return new UnauthorizedError();
            case StatusCodes.INTERNAL_SERVER_ERROR:
                return new InternalServerError();
            default:
                return new InternalServerError();
        }
    }
}
