export default abstract class AbstractHttpClient<T> {
    private readonly baseUrl: string;

    protected constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    protected abstract getHeaders(): Headers;

    public async post(endpoint: string, data: T): Promise<Response> {
        let requestInit: RequestInit = {
            method: "POST",
            headers: this.getHeaders(),
            body: data instanceof URLSearchParams ? data : JSON.stringify(data),
        };
        return await fetch(`${this.baseUrl}/${endpoint}`, requestInit);
    }

    public async get(endpoint: string): Promise<Response> {
        let requestInit: RequestInit = {
            method: "GET",
            headers: this.getHeaders(),
        };
        return await fetch(`${this.baseUrl}/${endpoint}`, requestInit);
    }
}
