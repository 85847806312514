import { CountryCode, findPhoneNumbersInText, isPossiblePhoneNumber, NumberFound } from "libphonenumber-js";
import { isNil } from "lodash";
import { PatientUserDetails } from "../model/PatientUserDetails";
import { toast } from "react-toastify";
import { CustomHttpError } from "../model/CustomHttpError";

export const validatePhoneNumber = (phoneNumber: string): boolean => {
    const numberData: NumberFound[] = findPhoneNumbersInText(phoneNumber);
    let countryCode: CountryCode;
    if (!isNil(numberData[0]) && !isNil(numberData[0].number) && !isNil(numberData[0].number.country)) {
        countryCode = numberData[0].number.country;
        return isPossiblePhoneNumber(phoneNumber, countryCode);
    }
    return false;
}

export const showToast = async (
    registerPatient: Promise<PatientUserDetails>,
    translate: (key: string) => string,
    isP5Patient: boolean
) => {
    toast
        .promise(registerPatient, {
            pending: translate("toast.pending"),
            success: isP5Patient ? translate("toast.success.p5Patient") : translate("toast.success.regularPatient"),
            error: {
                render({data}) {
                    return getErrorMessage(data as CustomHttpError, translate);
                },
            },
        })
        .catch((error) => {
            if (error instanceof CustomHttpError) {
                console.error(`${error.name} with status code ${error.httpCode}.`);
            } else {
                console.error(`An unexpected error occurred: ${error instanceof Error ? error.message : error}`);
            }
        });
}

export const getErrorMessage = (error: CustomHttpError, translate: (key: string) => string): string => {
    switch (error.httpCode) {
        case 400:
            return translate("toast.errors.400");
        case 409:
            return translate("toast.errors.409");
        case 424:
            return translate("toast.errors.424");
        default:
            return translate("toast.errors.default");
    }
}

export const createMarkup = (htmlString: string) => {
    return {__html: htmlString};
}

export const getEnvironmentVariable = (key: string): string => {
    if (process.env.NODE_ENV === "production") {
        return window._env_[key];
    } else {
        return process.env[key] as string;
    }
}
