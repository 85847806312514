import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ErrorHandler } from "../../model/CustomHttpError";
import { GridItem, useStyles } from "../components/styles";

export const ErrorPage = ({ errorCode, errorDescription }: { errorCode: number; errorDescription: string }) => {
    const styles = useStyles();
    const { t: translate } = useTranslation();

    return (
        <Box className={styles.errorViewBox}>
            <Grid item xs={12}>
                <GridItem elevation={0}>
                    <Box className="errorViewMessage">
                        <Typography variant="h1">{errorCode}</Typography>
                        <Typography variant="h4">{ErrorHandler.getErrorNameByCode(errorCode).name}</Typography>
                    </Box>
                    <Divider />
                    <Box className="errorViewDescription">
                        <Typography variant="h6">{translate(errorDescription)}</Typography>
                    </Box>
                </GridItem>
            </Grid>
        </Box>
    );
};
