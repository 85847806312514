export const messagesEn = () => {
    return {
        form: {
            fields: {
                p5Card: {
                    cardCode: {
                        label: "Card code",
                        helperText: {
                            text: "You can find the code on the back of the P5 card.",
                        },
                    },
                    cardPin: {
                        label: "Card PIN",
                        helperText: {
                            text: "You can find the PIN on the back of the P5 card.",
                        },
                    },
                },
                identifier: {
                    label: "Portuguese health user number",
                    helperText: {
                        text: "You can find your health user number on the back of your Portuguese Citizen Card.",
                        minAndMaxLength: "Must be 9 characters",
                    },
                },
                name: {
                    firstName: "First name",
                    lastName: "Last name",
                    helperText: {
                        minLength: "Minimum of 3 characters",
                        pattern: "Name cannot contain numbers or special characters",
                    },
                },
                dateOfBirth: {
                    label: "Date of birth",
                },
                gender: {
                    label: "Gender",
                    male: "Male",
                    female: "Female",
                    unknown: "Unknown",
                    other: "Prefer not to disclose",
                },
                contactPoint: {
                    phoneNumberLabel: "Phone number",
                    emailLabel: "Email",
                    helperText: {
                        phonePattern: "Phone number not be valid",
                        emailPattern: "Email not be valid",
                    },
                },
                protocols: {
                    label: "Protocol",
                    onError: "Could not load protocols",
                },
                domainResource: {
                    textLabel: "Clinical summary",
                },
                generalHelperText: {
                    required: "Required field",
                },
            },
            checkboxes: {
                dataManagement:
                    "I consent to the processing of the requested data for contact and/or service provision.",
                trueInfo: "I declare that all information provided is true.",
                termsAndConditions: `I have read and accept the <a href="https://www.p5.pt/politicas-de-privacidade/avaliador-de-sintomas" target="_blank">terms and conditions and privacy policy</a>.`,
                p5Card: "I want to use my P5 access card",
                emailMarketing:
                    "I would like to receive email communications from P5 to stay up to date with news and receive special offers and promotions.",
                helperText: {
                    required: "Required selection",
                },
            },
            buttons: {
                submit: "Register",
                clear: "Clear",
            },
        },
        errorView: {
            error500: {
                description: "Connection to required services has not been established.",
            },
            error401: {
                description: "Authentication failed. You don't have permissions to access some features on this page.",
            },
        },
        toast: {
            pending: "Registering your data. Please wait...",
            success: {
                regularPatient: "Your registration was successful!",
                p5Patient:
                    "Your registration with P5 was successful! You should receive an email in the next few moments to set your password.",
            },
            errors: {
                400: "We’re sorry, but we couldn’t register you right now. Please review your information and try again",
                409: "A patient with these details already exists. Please start a new session or recover your password",
                424: "Communication failure with the server. Please try again later",
                default: "Could not register your data. Please try again later",
            },
        },
        recaptcha: {
            error: "Recaptcha verification failed",
        },
    };
};
