import KeycloakHttpClient from "../http/KeycloakHttpClient";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { isNil } from "lodash";
import { CustomHttpError } from "../model/CustomHttpError";

export default class KeycloakService {
    private static instance: KeycloakService;
    private keycloakHttpClient: KeycloakHttpClient;

    constructor() {
        this.keycloakHttpClient = KeycloakHttpClient.getInstance();
    }

    public static getInstance(): KeycloakService {
        if (!KeycloakService.instance) {
            KeycloakService.instance = new KeycloakService();
        }
        return KeycloakService.instance;
    }

    public async checkTokenExpiration(): Promise<boolean> {
        const accessToken = localStorage.getItem("KEYCLOAK_ACCESS_TOKEN");
        if (accessToken) {
            const tokenExpiration = jwtDecode<JwtPayload>(accessToken).exp;
            const currentTime = Math.floor(Date.now() / 1000);
            if (!isNil(tokenExpiration)) {
                if (tokenExpiration < currentTime + 60) {
                    return await this.getAccessToken();
                } else {
                    return true;
                }
            } else {
                throw new CustomHttpError(404, "Token expiration is undefined.");
            }
        } else {
            return await this.getAccessToken();
        }
    }

    public async getAccessToken(): Promise<boolean> {
        try {
            const { access_token } = await this.keycloakHttpClient.getAccessToken();
            const { access_token: rpt } = await this.keycloakHttpClient.getRequestingPartyToken(access_token);
            localStorage.setItem("KEYCLOAK_ACCESS_TOKEN", rpt);
            return true;
        } catch (error) {
            if (error instanceof CustomHttpError) {
                console.error(`${error.name} with status code ${error.httpCode}.`);
            } else {
                console.error(`An unexpected error occurred: ${error instanceof Error ? error.message : error}`);
            }
            return false;
        }
    }
}
