import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import { createMarkup } from "../../utils/utils";
import { PatientFormProps } from "../../model/PatientUserDetails";
import { useTranslation } from "react-i18next";
import { GridItem, useStyles } from "./styles";
import { PatientBaseForm } from "./PatientBaseForm";
import { isEmpty, isNil } from "lodash";
import { Coding } from "fhir/r4";
import { getDefaultProtocol } from "../../utils/FhirUtils";
import FhirService from "../../services/FhirService";

export const P5PatientForm = ({ errors, register, setError, clearErrors }: PatientFormProps) => {
    const styles = useStyles();
    const { t: translate } = useTranslation();
    const [checked, setChecked] = useState(false);
    const [protocol, setProtocol] = useState("");
    const [availableProtocols, setAvailableProtocols] = useState<string[]>([]);
    const [availableProtocolsDescriptions, setAvailableProtocolsDescriptions] = useState<string>("");
    const fhirService: FhirService = FhirService.getInstance();

    const handleChangeCheckbox = (event: boolean) => {
        setChecked(event);
    };
    const handleChangeProtocol = (event: SelectChangeEvent) => {
        setProtocol(event.target.value);
        if (!isNil(clearErrors)) {
            clearErrors("protocol");
        }
    };

    useEffect(() => {
        (async () => {
            const availableProtocols: string[] = await fhirService.getAvailableProtocols();
            setAvailableProtocols(availableProtocols);
            const availableProtocolsDescriptions: string = await fhirService.getAvailableProtocolsDescriptions();
            setAvailableProtocolsDescriptions(availableProtocolsDescriptions);
        })();
    }, [fhirService]);

    return (
        <>
            <Grid item xs={12}>
                <GridItem elevation={0}>
                    <FormControlLabel
                        control={<Checkbox data-testid="p5CardCheckbox" />}
                        label={`${translate("form.checkboxes.p5Card")}`}
                        className={styles.formFields}
                        onClick={(value: BaseSyntheticEvent) => {
                            handleChangeCheckbox(value.target.checked);
                        }}
                        {...register("useP5Card")}
                    />
                </GridItem>
                {!checked ? <Divider /> : null}
            </Grid>
            {checked ? (
                <>
                    <Grid item xs={6}>
                        <GridItem elevation={0}>
                            <TextField
                                fullWidth
                                id="filled-basic"
                                label={`${translate("form.fields.p5Card.cardCode.label")}`}
                                variant="filled"
                                type="number"
                                helperText={
                                    (errors.cardCode && errors.cardCode.message?.toString()) ||
                                    `${translate("form.fields.p5Card.cardCode.helperText.text")}`
                                }
                                {...register("cardCode")}
                                error={!!errors.cardCode}
                            />
                        </GridItem>
                    </Grid>
                    <Grid item xs={6}>
                        <GridItem elevation={0}>
                            <TextField
                                fullWidth
                                id="filled-basic"
                                label={`${translate("form.fields.p5Card.cardPin.label")}`}
                                variant="filled"
                                type="number"
                                helperText={
                                    (errors.cardPin && errors.cardPin.message?.toString()) ||
                                    `${translate("form.fields.p5Card.cardPin.helperText.text")}`
                                }
                                {...register("cardPin")}
                                error={!!errors.cardPin}
                            />
                        </GridItem>
                    </Grid>
                    <Grid item xs={12}>
                        <GridItem elevation={0}>
                            <Divider />
                        </GridItem>
                    </Grid>
                </>
            ) : null}
            <PatientBaseForm
                errors={errors}
                register={register}
                setError={setError}
                clearErrors={clearErrors}
                emailRequired={!checked}
            />
            <Grid item xs={12}>
                <GridItem elevation={0}>
                    <FormControl variant="filled" className={styles.formFields}>
                        <InputLabel error={!!errors.protocol}>{`${translate(
                            "form.fields.protocols.label"
                        )}`}</InputLabel>
                        <Select
                            data-testid="protocolSelectBox"
                            {...register("protocol", { required: true })}
                            label={`${translate("form.fields.protocols.label")}`}
                            value={protocol !== "" ? protocol : getDefaultProtocol("none")}
                            onChange={handleChangeProtocol}
                            error={!!errors.protocol}>
                            {!isEmpty(availableProtocols) ? (
                                availableProtocols.map((protocol: string) => {
                                    let parsedProtocol: Coding = JSON.parse(protocol);
                                    return (
                                        <MenuItem key={parsedProtocol.code} value={protocol}>
                                            {parsedProtocol.display}
                                        </MenuItem>
                                    );
                                })
                            ) : (
                                <MenuItem value={getDefaultProtocol("none")}>
                                    {translate("form.fields.protocols.onError")}
                                </MenuItem>
                            )}
                        </Select>
                        {errors.protocol && (
                            <FormHelperText error>
                                <Typography variant="caption">{errors.protocol.message?.toString()}</Typography>
                            </FormHelperText>
                        )}

                        {!isEmpty(availableProtocols) && !isNil(availableProtocols) && (
                            <div dangerouslySetInnerHTML={createMarkup(`${availableProtocolsDescriptions}`)} />
                        )}
                    </FormControl>
                </GridItem>
            </Grid>
        </>
    );
};
