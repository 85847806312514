import FhirHttpClient from "../http/FhirHttpClient";

export default class FhirService {
    private static instance: FhirService;
    private fhirHttpClient: FhirHttpClient;

    constructor() {
        this.fhirHttpClient = FhirHttpClient.getInstance();
    }

    public static getInstance(): FhirService {
        if (!FhirService.instance) {
            FhirService.instance = new FhirService();
        }
        return FhirService.instance;
    }

    public async getAvailableProtocols(): Promise<string[]> {
        try {
            const response = await this.fhirHttpClient.expandFhirValueSet("active-partnerships");
            if (response.ok) {
                const valueSets = await response.json();
                return valueSets.expansion?.contains?.map((item: string) => `${JSON.stringify(item)}`);
            } else {
                return [];
            }
        } catch (error) {
            throw new Error("Error on expand 'active-partnerships' Value Set - " + error);
        }
    }

    public async getAvailableProtocolsDescriptions(): Promise<string> {
        try {
            const response = await this.fhirHttpClient.getFhirValueSet("active-partnerships");
            if (response.ok) {
                const valueSet = await response.json();
                if (valueSet.entry && valueSet.entry.length > 0 && valueSet.entry[0].resource.text) {
                    return valueSet.entry[0].resource.text.div;
                }
            }
            return "";
        } catch (error) {
            throw new Error("Error on get 'active-partnerships' Value Set - " + error);
        }
    }
}
