import AbstractHttpClient from "./AbstractHttpClient";
import { getEnvironmentVariable } from "../utils/utils";

export default class FhirHttpClient extends AbstractHttpClient<void> {
    private static FHIR_BASE_URL: string | undefined = getEnvironmentVariable("REACT_APP_FHIR_BASE_URL");
    private static TERMINOLOGY_URL: string | undefined = getEnvironmentVariable("REACT_APP_SERVER_TERMINOLOGY_URL");

    private static instance: FhirHttpClient;

    constructor() {
        if (FhirHttpClient.FHIR_BASE_URL === undefined) {
            throw new Error("REACT_APP_FHIR_BASE_URL variable is null or undefined");
        }
        if (FhirHttpClient.TERMINOLOGY_URL === undefined) {
            throw new Error("REACT_APP_SERVER_TERMINOLOGY_URL variable is null or undefined");
        }
        super(FhirHttpClient.FHIR_BASE_URL);
    }

    public static getInstance(): FhirHttpClient {
        if (!FhirHttpClient.instance) {
            FhirHttpClient.instance = new FhirHttpClient();
        }
        return FhirHttpClient.instance;
    }

    protected getHeaders(): Headers {
        const headers: Headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${localStorage.getItem("KEYCLOAK_ACCESS_TOKEN")}`);
        return headers;
    }

    public async expandFhirValueSet(name: string): Promise<Response> {
        return await this.get(`ValueSet/$expand?url=${FhirHttpClient.TERMINOLOGY_URL}/ValueSet/${name}`);
    }

    public async getFhirValueSet(name: string): Promise<Response> {
        return await this.get(`ValueSet?url=${FhirHttpClient.TERMINOLOGY_URL}/ValueSet/${name}`);
    }
}
