import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import KeycloakAuth from "./ui/components/auth/KeycloakAuth";
import KeycloakService from "./services/KeycloakService";
import { PatientPage } from "./ui/pages/PatientPage";
import { P5PatientPage } from "./ui/pages/P5PatientPage";

export default function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/patient" element={<PatientPage />} />
                    <Route
                        path="/patient-p5"
                        element={
                            <KeycloakAuth keycloakService={KeycloakService.getInstance()}>
                                <P5PatientPage />
                            </KeycloakAuth>
                        }
                    />
                </Routes>
            </Router>
            <ToastContainer />
        </>
    );
}
