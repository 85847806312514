import React, { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/material";
import KeycloakService from "../../../services/KeycloakService";
import { ErrorPage } from "../../pages/ErrorPage";

const KeycloakAuth = ({ children, keycloakService }: { children: any; keycloakService: KeycloakService }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const checkTokenExpiration = async () => {
            await keycloakService.checkTokenExpiration().then((isAuthenticated) => {
                setIsAuthenticated(isAuthenticated);
                setIsLoading(false);
            });
        };
        (async () => {
            await checkTokenExpiration();
        })();
        const tokenRenewalTimer = setInterval(checkTokenExpiration, 30000);
        return () => {
            clearInterval(tokenRenewalTimer);
        };
    }, [keycloakService]);

    if (isLoading) {
        return (
            <Box>
                <LinearProgress />
            </Box>
        );
    }

    if (!isAuthenticated) {
        return <ErrorPage errorCode={401} errorDescription="errorView.error401.description" />;
    }

    return <>{children}</>;
};
export default KeycloakAuth;
