import AbstractHttpClient from "./AbstractHttpClient";
import { AccessTokenResponse, RequestingPartyTokenResponse } from "../model/AuthTokenResponse";
import { getEnvironmentVariable } from "../utils/utils";

export default class KeycloakHttpClient extends AbstractHttpClient<URLSearchParams> {
    private static KEYCLOAK_BASE_URL: string | undefined = getEnvironmentVariable("REACT_APP_KEYCLOAK_BASE_URL");
    private static KEYCLOAK_REALM: string | undefined = getEnvironmentVariable("REACT_APP_KEYCLOAK_REALM");
    private static KEYCLOAK_CLIENT_ID: string | undefined = getEnvironmentVariable("REACT_APP_KEYCLOAK_CLIENT_ID");
    private static KEYCLOAK_CLIENT_SECRET: string | undefined = getEnvironmentVariable("REACT_APP_KEYCLOAK_CLIENT_SECRET");

    private static TOKEN_ENDPOINT: string = `realms/${KeycloakHttpClient.KEYCLOAK_REALM}/protocol/openid-connect/token`;

    private static instance: KeycloakHttpClient;

    private accessToken: string | undefined;

    constructor() {
        if (KeycloakHttpClient.KEYCLOAK_BASE_URL === undefined) {
            throw new Error("REACT_APP_KEYCLOAK_BASE_URL variable is null or undefined");
        }
        if (KeycloakHttpClient.KEYCLOAK_REALM === undefined) {
            throw new Error("REACT_APP_KEYCLOAK_REALM variable is null or undefined");
        }
        if (KeycloakHttpClient.KEYCLOAK_CLIENT_ID === undefined) {
            throw new Error("REACT_APP_KEYCLOAK_CLIENT_ID variable is null or undefined");
        }
        if (KeycloakHttpClient.KEYCLOAK_CLIENT_SECRET === undefined) {
            throw new Error("REACT_APP_KEYCLOAK_CLIENT_SECRET variable is null or undefined");
        }
        super(KeycloakHttpClient.KEYCLOAK_BASE_URL);
    }

    public static getInstance(): KeycloakHttpClient {
        if (!KeycloakHttpClient.instance) {
            KeycloakHttpClient.instance = new KeycloakHttpClient();
        }
        return KeycloakHttpClient.instance;
    }

    protected getHeaders(): Headers {
        const headers: Headers = new Headers();
        headers.append("Authorization", `Bearer ${this.accessToken}`);
        return headers;
    }

    public async getAccessToken(): Promise<AccessTokenResponse> {
        const data: URLSearchParams = new URLSearchParams();
        data.append("grant_type", "client_credentials");
        data.append("client_id", KeycloakHttpClient.KEYCLOAK_CLIENT_ID as string);
        data.append("client_secret", KeycloakHttpClient.KEYCLOAK_CLIENT_SECRET as string);
        data.append("scope", "openid");

        const response: Response = await this.post(KeycloakHttpClient.TOKEN_ENDPOINT, data);
        if (!response.ok) {
            throw new Error("Failed to get token");
        }

        return await response.json();
    }

    public async getRequestingPartyToken(accessToken: string): Promise<RequestingPartyTokenResponse> {
        this.accessToken = accessToken;
        const data: URLSearchParams = new URLSearchParams();
        data.append("grant_type", "urn:ietf:params:oauth:grant-type:uma-ticket");
        data.append("client_id", KeycloakHttpClient.KEYCLOAK_CLIENT_ID as string);
        data.append("audience", "ehr-server");

        const response: Response = await this.post(KeycloakHttpClient.TOKEN_ENDPOINT, data);
        if (!response.ok) {
            throw new Error("Failed to get RPT");
        }

        return await response.json();
    }
}
