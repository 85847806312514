import { Max, Min } from "class-validator";
import { UserServiceModel } from "./PatientUserDetails";

export interface ReCAPTCHAErrorMapInterface {
    [key: string]: string;
}

export const ReCAPTCHAErrorMap: ReCAPTCHAErrorMapInterface = {
    "missing-input-secret": "The secret parameter is missing.",
    "invalid-input-secret": "The secret parameter is invalid or malformed.",
    "missing-input-response": "The response parameter is missing.",
    "invalid-input-response": "The response parameter is invalid or malformed.",
    "bad-request": "The request is invalid or malformed.",
    "timeout-or-duplicate": "The response is no longer valid: either is too old or has been used previously.",
};

export interface ReCAPTCHAErrorResponse {
    title: string;
    status: number;
    detail: string;
}

export interface ReCAPTCHARequest extends UserServiceModel {
    token: string;
}

export class ReCAPTCHARequest {
    token: string;

    constructor(token: string) {
        this.token = token;
    }
}

export interface ReCAPTCHA {
    "error-codes": string[];
    success: boolean;
    challenge_ts: Date;
    hostname: string;
    score: number;
    action: string;
}

export class ReCAPTCHA {
    success: boolean;
    challengeTs: Date;
    hostname: string;
    @Min(0.0)
    @Max(1.0)
    score: number;
    action: string;
    errorCodes: string[];

    constructor(serverResponse: ReCAPTCHA) {
        this.errorCodes = serverResponse["error-codes"];
        this.success = serverResponse.success;
        this.challengeTs = serverResponse["challenge_ts"];
        this.hostname = serverResponse.hostname;
        this.score = serverResponse.score;
        this.action = serverResponse.action;
    }
}
