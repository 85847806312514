import React, { useRef, useState } from "react";
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import { PatientFormProps } from "../../model/PatientUserDetails";
import { GridItem, useStyles } from "./styles";
import { MuiTelInput } from "mui-tel-input";
import { validatePhoneNumber } from "../../utils/utils";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

export const PatientBaseForm = ({ errors, register, setError, clearErrors, emailRequired }: PatientFormProps) => {
    const styles = useStyles();
    const { t: translate } = useTranslation();
    const [gender, setGender] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const phoneInputRef = useRef<HTMLInputElement>(null);
    const genderSelectBoxRef = useRef<HTMLInputElement>(null);

    const handleChangeGender = (event: SelectChangeEvent) => {
        setGender(event.target.value);
        genderSelectBoxRef.current?.focus();
        clearErrors?.("gender");
    };

    const handleChangePhone = (phoneNumber: string) => {
        setPhone(phoneNumber);
        phoneInputRef.current?.focus();

        if (!isEmpty(phoneNumber)) {
            validatePhoneNumber(phoneNumber)
                ? clearErrors?.("phoneNumber")
                : setError?.("phoneNumber", { type: "validNumber" });
        } else {
            setError?.("phoneNumber", { type: "required" });
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <GridItem elevation={0}>
                    <TextField
                        fullWidth
                        id="filled-basic"
                        label={`${translate("form.fields.identifier.label")}`}
                        variant="filled"
                        type="number"
                        helperText={
                            (errors.healthcareServiceIdentifier &&
                                errors.healthcareServiceIdentifier.message?.toString()) ||
                            `${translate("form.fields.identifier.helperText.text")}`
                        }
                        {...register("healthcareServiceIdentifier")}
                        error={!!errors.healthcareServiceIdentifier}
                    />
                </GridItem>
            </Grid>
            <Grid item xs={6}>
                <GridItem elevation={0}>
                    <TextField
                        fullWidth
                        id="filled-basic"
                        label={`${translate("form.fields.name.firstName")}`}
                        variant="filled"
                        helperText={errors.firstName && errors.firstName.message?.toString()}
                        {...register("firstName")}
                        error={!!errors.firstName}
                    />
                </GridItem>
            </Grid>
            <Grid item xs={6}>
                <GridItem elevation={0}>
                    <TextField
                        fullWidth
                        id="filled-basic"
                        label={`${translate("form.fields.name.lastName")}`}
                        variant="filled"
                        helperText={errors.lastName && errors.lastName.message?.toString()}
                        {...register("lastName")}
                        error={!!errors.lastName}
                    />
                </GridItem>
            </Grid>
            <Grid item xs={6}>
                <GridItem elevation={0}>
                    <TextField
                        fullWidth
                        data-testid="nif"
                        id="date"
                        label={`${translate("form.fields.dateOfBirth.label")}`}
                        variant="filled"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        helperText={errors.dateOfBirth && errors.dateOfBirth.message?.toString()}
                        {...register("dateOfBirth")}
                        error={!!errors.dateOfBirth}
                    />
                </GridItem>
            </Grid>
            <Grid item xs={6}>
                <GridItem elevation={0}>
                    <FormControl variant="filled" className={styles.formFields}>
                        <InputLabel error={!!errors.gender}>{translate("form.fields.gender.label")}</InputLabel>
                        <Select
                            inputRef={genderSelectBoxRef}
                            data-testid="genderSelectBox"
                            {...register("gender", { required: true })}
                            value={gender}
                            onChange={handleChangeGender}
                            error={!!errors.gender}>
                            <MenuItem value="MALE">{translate("form.fields.gender.male")}</MenuItem>
                            <MenuItem value="FEMALE">{translate("form.fields.gender.female")}</MenuItem>
                            <MenuItem value="UNKNOWN">{translate("form.fields.gender.unknown")}</MenuItem>
                            <MenuItem value="OTHER">{translate("form.fields.gender.other")}</MenuItem>
                        </Select>
                        {errors.gender && (
                            <FormHelperText error>
                                <Typography variant="caption">{errors.gender.message?.toString()}</Typography>
                            </FormHelperText>
                        )}
                    </FormControl>
                </GridItem>
            </Grid>
            <Grid item xs={6}>
                <GridItem elevation={0}>
                    <MuiTelInput
                        fullWidth
                        inputRef={phoneInputRef}
                        defaultCountry="PT"
                        variant="filled"
                        label={`${translate("form.fields.contactPoint.phoneNumberLabel")}`}
                        value={phone}
                        helperText={
                            errors.phoneNumber?.message?.toString() ||
                            (errors.phoneNumber?.type === "validNumber"
                                ? `${translate("form.fields.contactPoint.helperText.phonePattern")}`
                                : "") ||
                            (errors.phoneNumber?.type === "required"
                                ? `${translate("form.fields.generalHelperText.required")}`
                                : "")
                        }
                        {...register("phoneNumber")}
                        error={!!errors.phoneNumber}
                        onChange={handleChangePhone}
                    />
                </GridItem>
            </Grid>
            <Grid item xs={6}>
                <GridItem elevation={0}>
                    <TextField
                        fullWidth
                        id="email"
                        label={`${translate("form.fields.contactPoint.emailLabel")}`}
                        variant="filled"
                        helperText={emailRequired ? errors.email && errors.email.message?.toString() : ""}
                        {...register("email")}
                        error={!!errors.email && emailRequired}
                    />
                </GridItem>
            </Grid>
            <Grid item xs={12}>
                <GridItem elevation={0}>
                    <TextField
                        fullWidth
                        multiline
                        rows={5}
                        id="filled-basic"
                        label={`${translate("form.fields.domainResource.textLabel")}`}
                        variant="filled"
                        {...register("medicalSummary")}
                        error={!!errors.medicalSummary}
                    />
                </GridItem>
            </Grid>
        </>
    );
};
