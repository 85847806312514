import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styling/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n/i18nProvider";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { getEnvironmentVariable } from "./utils/utils";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <GoogleReCaptchaProvider
        useRecaptchaNet
        reCaptchaKey={getEnvironmentVariable("REACT_APP_RECAPTCHA_KEY")}
        scriptProps={{async: true, defer: true, appendTo: "body"}}
        container={{
            element: "recaptchaBadge",
            parameters: {
                badge: "inline",
            },
        }}>
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    </GoogleReCaptchaProvider>
);
reportWebVitals();
