import React from "react";
import { Box, Grid } from "@mui/material";
import { GridItem, useStyles } from "./styles";

export const GoogleReCaptchaBadge = () => {
    const styles = useStyles();
    return (
        <Grid item xs={12}>
            <GridItem elevation={0}>
                <Box id="recaptchaBadge" data-testid="recaptchaBadge" className={styles.recaptchaBadge}></Box>
            </GridItem>
        </Grid>
    );
};
