import React from "react";
import { CardContent, Grid, useMediaQuery } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useValidationResolver, useYupValidationResolver } from "../../validations/yupValidations";
import { PatientUserDetails } from "../../model/PatientUserDetails";
import { PatientBaseForm } from "../components/PatientBaseForm";
import { showToast } from "../../utils/utils";
import { AgreeCheckboxes } from "../components/AgreeCheckboxes";
import { FormButtons } from "../components/FormButtons";
import { GoogleReCaptchaBadge } from "../components/GoogleReCaptchaBadge";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import PatientPageController from "./PatientPageController";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { GridItem } from "../components/styles";
import { useLocation } from "react-router-dom";
import ReCAPTCHAService from "../../services/ReCAPTCHAService";

export const PatientPage = () => {
    const { t: translate } = useTranslation();
    const { executeRecaptcha: recaptcha } = useGoogleReCaptcha();
    const smallDevices = useMediaQuery("(max-width: 600px)");
    const { validationSchema } = useValidationResolver();
    const resolver = useYupValidationResolver(validationSchema);
    const defaultProtocol = new URLSearchParams(useLocation().search).get("protocol");
    const patientPageController = PatientPageController.getInstance();
    const recaptchaService: ReCAPTCHAService = ReCAPTCHAService.getInstance();
    const {
        register,
        setError,
        clearErrors,
        handleSubmit,
        formState: { errors },
    } = useForm<PatientUserDetails>({ resolver });
    const onSubmit: SubmitHandler<PatientUserDetails> = async (data: PatientUserDetails) => {
        recaptchaService
            .validateRecaptcha(recaptcha)
            .then(async () => {
                await showToast(patientPageController.createPatient(data, false, defaultProtocol), translate, false);
            })
            .catch((error: Error) => {
                toast(translate("recaptcha.error"), { type: "error" });
                throw new Error(error.message);
            });
    };
    return (
        <CardContent component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} direction={smallDevices ? "column" : "row"}>
                <PatientBaseForm
                    errors={errors}
                    register={register}
                    setError={setError}
                    clearErrors={clearErrors}
                    emailRequired={true}
                />
                <Grid item xs={12}>
                    <GridItem elevation={0}>
                        <AgreeCheckboxes
                            errors={errors}
                            register={register}
                            setError={setError}
                            clearErrors={clearErrors}
                        />
                    </GridItem>
                </Grid>
            </Grid>
            <GoogleReCaptchaBadge />
            <FormButtons />
        </CardContent>
    );
};
