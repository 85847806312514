import UsersServiceHttpClient from "../http/UsersServiceHttpClient";
import { ReCAPTCHA, ReCAPTCHAErrorMap, ReCAPTCHAErrorResponse } from "../model/ReCAPTCHA";
import { isEmpty } from "lodash";

export default class ReCAPTCHAService {
    private static instance: ReCAPTCHAService;
    private usersHttpClient: UsersServiceHttpClient;

    constructor() {
        this.usersHttpClient = UsersServiceHttpClient.getInstance();
    }

    public static getInstance(): ReCAPTCHAService {
        if (!ReCAPTCHAService.instance) {
            ReCAPTCHAService.instance = new ReCAPTCHAService();
        }
        return ReCAPTCHAService.instance;
    }

    public async verifyToken(token: string): Promise<Response> {
        return await this.usersHttpClient.verifyRecaptcha(token);
    }

    public async validateRecaptcha(
        executeRecaptcha: ((action?: string) => Promise<string>) | undefined
    ): Promise<boolean> {
        let success: boolean = false;
        if (executeRecaptcha) {
            await executeRecaptcha("dynamicAction").then(async (token) => {
                const response = await this.verifyToken(token);
                if (!response.ok) {
                    const errorResponse: ReCAPTCHAErrorResponse = await response.json();
                    throw new Error(
                        `Error requesting reCAPTCHA token verification: ${errorResponse.title} (${errorResponse.status}): ${errorResponse.detail}`
                    );
                }
                const responseData = await response.json();
                const reCAPTCHAData: ReCAPTCHA = new ReCAPTCHA(responseData);
                if (!isEmpty(reCAPTCHAData.errorCodes)) {
                    const errorCodes: string[] = reCAPTCHAData.errorCodes.map(
                        (errorCode: string) => ReCAPTCHAErrorMap[errorCode]
                    );
                    throw new Error(`Error reCAPTCHA verification, with error code: ${errorCodes.join(", ")}`);
                } else {
                    success = reCAPTCHAData.success;
                }
            });
        }
        return success;
    }
}
