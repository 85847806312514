import { PatientUserDetails } from "../../model/PatientUserDetails";
import { ErrorHandler } from "../../model/CustomHttpError";
import { isNil } from "lodash";
import { defaultProtocolsMapping, getDefaultProtocol } from "../../utils/FhirUtils";
import PatientUserDetailsService from "../../services/PatientUserDetailsService";

export default class PatientPageController {
    private static instance: PatientPageController;

    private patientUserDetailsService: PatientUserDetailsService;

    constructor() {
        this.patientUserDetailsService = PatientUserDetailsService.getInstance();
    }

    public static getInstance(): PatientPageController {
        if (!PatientPageController.instance) {
            PatientPageController.instance = new PatientPageController();
        }
        return PatientPageController.instance;
    }

    public async createPatient(
        data: PatientUserDetails,
        createAccount: boolean,
        defaultProtocolCode?: string | null
    ): Promise<PatientUserDetails> {
        if (!isNil(defaultProtocolCode) && !isNil(defaultProtocolsMapping[defaultProtocolCode])) {
            data.protocol = getDefaultProtocol(defaultProtocolCode);
        }
        return new Promise((resolve, reject) => {
            this.patientUserDetailsService
                .createPatientUserDetails(data, createAccount)
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw ErrorHandler.getErrorNameByCode((response as Response).status);
                })
                .then((json) => resolve(json))
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
