import { PatientUserDetails } from "../model/PatientUserDetails";
import { isNil } from "lodash";
import UsersServiceHttpClient from "../http/UsersServiceHttpClient";

export default class PatientUserDetailsService {
    private static instance: PatientUserDetailsService;

    private usersHttpClient: UsersServiceHttpClient;

    constructor() {
        this.usersHttpClient = UsersServiceHttpClient.getInstance();
    }

    public static getInstance(): PatientUserDetailsService {
        if (!PatientUserDetailsService.instance) {
            PatientUserDetailsService.instance = new PatientUserDetailsService();
        }
        return PatientUserDetailsService.instance;
    }

    public async createPatientUserDetails(
        patientUserDetails: PatientUserDetails,
        createAccount: boolean
    ): Promise<Response> {
        patientUserDetails.createAccount = createAccount;
        if (isNil(patientUserDetails.emailMarketingSubscribe)) {
            patientUserDetails.emailMarketingSubscribe = false;
        }
        return await this.usersHttpClient.createPatientUserDetails(patientUserDetails);
    }
}
